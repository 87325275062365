import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { client } from 'cccisd-apollo';
import axios from 'cccisd-axios';
import { RegisterLoginPage } from 'cccisd-laravel-nexus';
import Loader from 'cccisd-loader';
import notification from 'cccisd-notification';
import { Field, CccisdSelect } from 'cccisd-formik';

import validGroupQuery from './graphql/validGroup.graphql';

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

const TeacherRegister = () => {
    const [show404, setShow404] = useState(false);
    const [groupId, setGroupId] = useState(null);
    const [loading, setLoading] = useState(true);

    const { hash } = useParams();

    useEffect(() => {
        const checkGroupHashValid = async () => {
            const result = await client.query({
                query: validGroupQuery,
                variables: { hash },
                fetchPolicy: 'network-only',
            });
            const id = result.data.groups.site.group.groupId;
            setShow404(!id);
            setGroupId(id);
            setLoading(false);
        };
        checkGroupHashValid();
    }, []);

    const registerLoginProps = {
        registerProps: {
            role: 'instructor',
            group: groupId,
            showPasswordFields: true,
            validateAdditionalFields: values => {
                let errors = {};

                if (!values.first_name) {
                    errors.first_name = 'First name is required.';
                }
                if (!values.last_name) {
                    errors.last_name = 'Last name is required.';
                }
                if (!values.grade) {
                    errors.grade = 'Grade-Level is required.';
                }

                return errors;
            },
            renderAdditionalFields: () => {
                return (
                    <>
                        <Field
                            name="grade"
                            component={CccisdSelect}
                            label="Grade-Level"
                            options={[
                                { value: '', label: '-- Select Grade-Level --' },
                                { value: 'Kindergarten', label: 'Kindergarten' },
                                { value: '1st Grade', label: '1st Grade' },
                                { value: '2nd Grade', label: '2nd Grade' },
                                { value: '3rd Grade', label: '3rd Grade' },
                                { value: '4th Grade', label: '4th Grade' },
                                { value: '5th Grade', label: '5th Grade' },
                                { value: '6th Grade', label: '6th Grade' },
                                { value: '7th Grade', label: '7th Grade' },
                                { value: '8th Grade', label: '8th Grade' },
                                { value: 'Multiple Grades', label: 'Multiple Grades' },
                            ]}
                        />
                    </>
                );
            },
            onRegisterSubmit: async values => {
                // Must copy values before manipulating or it will mutate Formik state object
                const formValues = { ...values };
                formValues.group = groupId;
                formValues.username = formValues.username || formValues.email;
                const registerResponse = await axios.post(Boilerplate.route('api.nexus.pawn.store'), {
                    ...formValues,
                    role: 'instructor',
                });

                if (registerResponse.status !== 200) {
                    notification({
                        message: 'Could not register at this time.',
                        type: 'danger',
                        duration: 5000,
                        data: registerResponse.data && registerResponse.data,
                    });
                    return;
                }

                // CUSTOM BIT HERE ////////////////////////////////////////
                //
                // If username already exists, check to see if it is Public User that we
                //  can replace with Teacher role
                let tryLoginAnyway = false;
                try {
                    if (
                        registerResponse.data.status === 'error' &&
                        registerResponse.data.errors.username[0].includes('belongs to an active user')
                    ) {
                        const replaceUserResp = await axios.post(Boilerplate.route('api.app.replacePublicPawn'), {
                            groupId,
                            groupHash: hash,
                            username: formValues.username,
                        });

                        if (replaceUserResp.data.status === 'success') {
                            tryLoginAnyway = true;
                        }
                    }
                } catch (e) {
                    // meh, just move on
                }
                // //////////////////////////////////////////////////////////

                if (registerResponse.data.status === 'success' || tryLoginAnyway) {
                    const loginResponse = await axios.post(Boilerplate.route('api.nexus.login'), {
                        username: formValues.username,
                        password: formValues.password,
                    });

                    if (loginResponse.status !== 200) {
                        notification({
                            message: 'Registration was successful, but you could not login at this time.',
                            type: 'danger',
                            duration: 5000,
                        });
                        return;
                    }

                    if (loginResponse.data.notifications.length > 0) {
                        loginResponse.data.notifications.forEach(note => notification(note));
                    }

                    if (loginResponse.data.status === 'success') {
                        let afterLoginUrl = Fortress.settings.config.after_login_url;
                        if (loginResponse.data.data.hasIncompleteSiteRegistration) {
                            window.location = Boilerplate.url(
                                `/account/registrationSurvey?redirectUrl=${afterLoginUrl}`
                            );
                        } else {
                            window.location = Boilerplate.url(afterLoginUrl);
                        }
                    } else {
                        notification({
                            message: 'Registration was successful, but you could not login at this time.',
                            type: 'danger',
                            duration: 5000,
                            data: loginResponse.data.errors,
                        });
                    }
                } else if (registerResponse.data.status === 'error') {
                    // If the email field is being used as username,
                    // replace 'username' error with 'email' error
                    if (!values.username && registerResponse.data.errors.username) {
                        registerResponse.data.errors.email = registerResponse.data.errors.username;
                        delete registerResponse.data.errors.username;
                    }

                    // errors may come back as something like {values: "Missing required middle name."},
                    // so these will be displayed in a notification instead of styling in the form
                    const isErrorLocationInForm = !Object.keys(registerResponse.data.errors).find(
                        errLocation => !(errLocation in values)
                    );

                    // Throwing errors object will be picked up by formik, and style the incorrect input fields
                    if (isErrorLocationInForm) {
                        throw registerResponse.data.errors;
                    } else {
                        notification({
                            message: 'Failed to register',
                            duration: 10000,
                            type: 'danger',
                            data: registerResponse.data.errors,
                        });
                    }
                }

                if (registerResponse.data.notifications.length > 0) {
                    registerResponse.data.notifications.forEach(note => notification(note));
                }
            },
        },

        loginProps: {
            onSuccess: async initialResponse => {
                // This will replace Public User pawn (success), or just do nothing (fail)
                await axios.post(Boilerplate.route('api.app.replacePublicPawn'), {
                    groupId,
                    groupHash: hash,
                    username: initialResponse.data.data.username,
                });
            },
        },
    };

    if (show404) {
        return (
            <div className="not-found">
                <h2>400 ERROR</h2>
                <div style={{ marginBottom: '1em' }}>
                    Sorry, the URL you used is missing or has incorrect information. Please contact an administrator for
                    support!
                </div>
            </div>
        );
    }

    if (loading) {
        return <Loader loading />;
    }

    return (
        <>
            <div style={{ color: '#495879', padding: '40px', display: 'flex', justifyContent: 'center' }}>
                <h2>Welcome to Lions Quest! Let&#39;s get started.</h2>
            </div>
            <RegisterLoginPage {...registerLoginProps} />
            <br />
            <br />
        </>
    );
};

export default TeacherRegister;
