import React, { useState, useContext } from 'react';
import { ResourceCenter } from 'cccisd-laravel-resources';
import { useHistory, useLocation } from 'cccisd-react-router';

import GradePicker from '../../components/GradePicker';
import { brandK2, brand68, brand912 } from '../Lessons';
import { LicensingContext } from '../../components/LicensingWrapper';

const Fortress = window.cccisd.fortress;

const options = [
    {
        label: 'Grades K-5',
        value: 'k5',
        color: brandK2,
    },
    {
        label: 'Grades 6-8',
        value: '68',
        color: brand68,
    },
    {
        label: 'Grades 9-12',
        value: '912',
        color: brand912,
    },
];

// use pawnId to scope in case someone has multiple roles, it remembers preferences per role
const localStorageKey = 'cccisd.lionsquest.resources.' + Fortress.user.acting.id;

const Resources = () => {
    const { licensingData, groupingUnitData } = useContext(LicensingContext);
    if (!licensingData.isValid) {
        return <span />;
    }

    const { showK5, show68, show912, showK6, show58 } = groupingUnitData;

    if (!showK5 && !show68 && !show912 && !showK6 && !show58) {
        return (
            <div className="container">
                <br />
                <div className="alert alert-info">
                    <p>No grade levels are turned on for your District. Please contact administrator.</p>
                </div>
            </div>
        );
    }

    let initialSelected = window.localStorage.getItem(localStorageKey) || '';

    // never set selected to something they can't see
    if (
        !options.map(o => o.value).includes(initialSelected) ||
        (initialSelected === 'k5' && !showK5) ||
        (initialSelected === '68' && !show68) ||
        (initialSelected === '912' && !show912)
    ) {
        initialSelected = '';
    }

    if (!initialSelected && (showK5 || showK6)) {
        initialSelected = 'k5';
    } else if (!initialSelected && (show68 || show58)) {
        initialSelected = '68';
    } else if (!initialSelected && show912) {
        initialSelected = '912';
    }

    const history = useHistory();
    const location = useLocation();
    const [selected, setSelected] = useState(initialSelected || 'k5');
    const gradePickerColor = options.find(opt => opt.value === selected)?.color;

    return (
        <>
            <GradePicker
                gradePickerColor={gradePickerColor}
                localStorageKey={localStorageKey}
                options={options.filter(opt => {
                    if (opt.value === 'k5') {
                        return showK5 || showK6 || show58;
                    }
                    if (opt.value === '68') {
                        return show68 || showK6 || show58;
                    }
                    if (opt.value === '912') {
                        return show912;
                    }
                    return false;
                })}
                selected={selected}
                setSelected={val => {
                    // switching grade levels should always start user back at first tab
                    if (location.search || location.pathname !== '/resources') {
                        history.replace('/resources');
                    }
                    setSelected(val);
                }}
            />

            <ResourceCenter handle={selected} key={selected} />
        </>
    );
};

export default Resources;
